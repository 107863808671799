import React, { useEffect, useState } from 'react'
import { transparentize } from 'polished'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useRewriteSearchString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { DataTable } from 'frontend/components'
import { AlphaColors, Colors } from 'frontend/utils/pulseStyles'

import { getExportConfig } from './getExportConfig'
import { getColumnConfig } from './column-config'
import { getUtilityBar } from './getUtilityBar'
import { formatColumnData } from './column-config/formatColumnData'

const EXCEL_LABEL = 'Payer Policy Tracker'
const TABLE_ID = 'payer-policy-tracker-v1'

const TABLE_STYLE_CONFIG = {
  superheaders: {
    display: 'flex',
    justifyContent: 'center',
    background: transparentize(0.9, Colors.PRIMARY),
    color: Colors.PRIMARY,
    fontSize: 11,
    letterSpacing: -0.2,
    fontWeight: 600,
    lineHeight: '16px',
    padding: 0,
    borderBottom: 'none',
    borderRight: `1px solid ${AlphaColors.Black10}`,
  },
}

const superHeaderStyle = {
  '.table .header > div:first-child': {
    background: Colors.BLUE_100,
  },
}

// const utilityBar = getUtilityBar()

const Criteria = ({ data }) => {
  const {
    shouldShowPayerPolicyM2,
    shouldFetchLatestPolicyProducts,
  } = useFlags()
  const [isDisplayed, setIsDisplayed] = useState(false)

  let version = 'v1'
  if (shouldFetchLatestPolicyProducts) {
    version = 'v2'
  }

  useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true)
    }, 1)
  }, [])

  const rewriteSearchString = useRewriteSearchString()
  const { isLoading } = usePayerPolicyDocument({
    queryInput: {
      areLatest: true,
      version,
    },
  })
  const formattedData = formatColumnData(data)

  const colConfig = getColumnConfig({ setModalType: rewriteSearchString })
  const exportConfig = getExportConfig()

  return (
    <DataTable
      tableId={TABLE_ID}
      data={formattedData}
      isLoading={isLoading || !isDisplayed}
      isFocusable
      isPaginated
      hasReset
      hasExport
      hasRowCount={!shouldShowPayerPolicyM2}
      exportConfig={exportConfig}
      excelLabel={EXCEL_LABEL}
      isHoverable
      colConfig={colConfig}
      isEditable
      editAccessor={'id'}
      paginationConfig={{
        wrapperStyle: { alignSelf: 'flex-start' },
        pageSize: 120,
      }}
      defaultColWidths={{ minWidth: 50, width: 200 }}
      // queryStringConfig={{
      //   showPageNumber: true,
      //   showSearchFilter: true,
      //   showSort: true,
      //   filterAccessorsToShow,
      // }}
      styleConfig={TABLE_STYLE_CONFIG}
      // utilityBarNodes={utilityBar}
      wrapperStyle={superHeaderStyle}
    />
  )
}

export default Criteria
