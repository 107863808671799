import React from 'react'

const TriangleCaution = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6306 0.17249C16.8658 0.298209 17.0583 0.491046 17.1837 0.726476L30.8714 26.4387C31.2203 27.0941 30.9718 27.9084 30.3164 28.2573C30.1219 28.3608 29.9049 28.415 29.6846 28.415H2.25456C1.512 28.415 0.910034 27.813 0.910034 27.0705C0.910034 26.8494 0.964556 26.6317 1.06877 26.4367L14.811 0.724518C15.1611 0.0696236 15.9757 -0.177527 16.6306 0.17249ZM16.7492 21.0001H15.2492C14.835 21.0001 14.4992 21.3359 14.4992 21.7501V23.2501C14.4992 23.6643 14.835 24.0001 15.2492 24.0001H16.7492C17.1634 24.0001 17.4992 23.6643 17.4992 23.2501V21.7501C17.4992 21.3359 17.1634 21.0001 16.7492 21.0001ZM16.7492 10.5001H15.2492C14.835 10.5001 14.4992 10.8359 14.4992 11.2501V18.7501C14.4992 19.1643 14.835 19.5001 15.2492 19.5001H16.7492C17.1634 19.5001 17.4992 19.1643 17.4992 18.7501V11.2501C17.4992 10.8359 17.1634 10.5001 16.7492 10.5001Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default TriangleCaution
