import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { RowCount } from './RowCount'
import { ViewTabs } from './ViewTabs'

interface Props {
  rowCount: number
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '10px 10px 10px 12px',
  position: 'relative',
  marginTop: -60,
  top: 57,
  width: 370,
})

const UtilityBar = ({ rowCount, activeTab, setActiveTab }: Props) => {
  return (
    <Wrapper>
      <ViewTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <RowCount rowCount={rowCount} />
    </Wrapper>
  )
}

export default UtilityBar
