import React from 'react'

import SidebarHeader from '../v0/SidebarHeader'
import ImportModal from './ImportModal'
import PDFViewer from './PDFViewer'
import Table from './Table'

const PayerPolicyTracker = () => {
  return (
    <div style={{ display: 'flex' }}>
      <SidebarHeader />
      <Table />
      <PDFViewer />
      <ImportModal />
    </div>
  )
}

export default PayerPolicyTracker
