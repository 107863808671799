import React from 'react'

const DownCaret = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.36752 8.8699L1.25935 4.21349C0.91355 3.82155 0.91355 3.18608 1.25935 2.79413C1.60515 2.40219 2.1658 2.40219 2.5116 2.79413L6 6.74806L9.4884 2.79413C9.8342 2.40219 10.3949 2.40219 10.7407 2.79413C11.0864 3.18608 11.0864 3.82155 10.7407 4.21349L6.63248 8.8699C6.28317 9.26582 5.71683 9.26582 5.36752 8.8699Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default DownCaret
