import React from 'react'
import styled from '@emotion/styled'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { Colors } from 'frontend/utils/pulseStyles'

const ICON_STYLES = {
  display: 'flex',
  width: '18px',
  height: '18px',
  marginRight: 1,
}

const Label = styled.label({
  fontFamily: 'Inter',
  fontSize: 12,
  fontWeight: 500,
  color: Colors.GRAY_600,
})

export const getMenuRows = () => {
  return [
    {
      label: (
        <>
          <CustomSvgIcon
            iconName="CircleCheck"
            iconColor="#12934E"
            styles={ICON_STYLES}
          />
          <Label>Approved</Label>
        </>
      ),
      value: 'approved',
    },
    {
      label: (
        <>
          <CustomSvgIcon
            iconName="Caution"
            iconColor="#FF8B33"
            styles={ICON_STYLES}
          />
          <Label>Needs Approval</Label>
        </>
      ),
      value: 'needs_approval',
    },
    {
      label: (
        <>
          <CustomSvgIcon
            iconName="CircleX"
            iconColor="#AF3528"
            styles={ICON_STYLES}
          />
          <Label>Incorrect Policy</Label>
        </>
      ),
      value: 'incorrect_policy',
    },
    {
      label: (
        <>
          <CustomSvgIcon
            iconName="Error"
            iconColor="#798BA3"
            styles={ICON_STYLES}
          />
          <Label>N/A</Label>
        </>
      ),
      value: 'not_applicable',
    },
  ]
}
