import React from 'react'

import StyledTab from '../Tab'
import Icon from './Icon'

interface Props {
  activeTab: string
}

const Policies = ({ activeTab }: Props) => {
  return (
    <StyledTab value="policies">
      <Icon activeTab={activeTab} />
      Policies
    </StyledTab>
  )
}

export default Policies
