import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useQueryString } from 'frontend/hooks'

import { BulkStatusButton as Button } from './Button'
import { DropdownMenu as Menu } from './Menu'

interface Props {
  setPolicyState: any
  productsState: any
  setProductsState: any
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: 10,
})

export const SetBulkStatusButton = ({
  setPolicyState,
  productsState,
  setProductsState,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { shouldShowBulkApproval } = useFlags()
  const { view } = useQueryString()

  const shouldShowButton = shouldShowBulkApproval && view === 'policies'
  if (!shouldShowButton) return null

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Wrapper>
      <Button handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        handleClose={handleClose}
        setPolicyState={setPolicyState}
        productsState={productsState}
        setProductsState={setProductsState}
      />
    </Wrapper>
  )
}
