export const getFlattenedData = (data: any[]) => {
  const flattenedData = data.reduce((acc: any[], row: any) => {
    const { products, ...rest } = row
    const productRows = products.map((product: any) => ({
      ...rest,
      products: [product],
    }))

    return [...acc, ...productRows]
  }, [])

  return flattenedData
}
