import React from 'react'

const Flag = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.01788 12.832V9.29037C3.0062 9.28955 3.98549 9.45964 4.90239 9.79003L6.09236 10.2189V10.2197C7.446 10.708 8.89191 10.9587 10.3512 10.957H10.8141C11.3051 10.957 11.7762 10.7813 12.1235 10.4687C12.4707 10.1562 12.666 9.73224 12.666 9.29037V3.66537C12.666 3.22347 12.4707 2.7995 12.1235 2.487C11.7762 2.1745 11.3051 1.9987 10.8141 1.9987H10.3512C9.36287 1.99952 8.38358 1.82943 7.46667 1.49904L6.27671 1.06935C4.92306 0.58106 3.47715 0.330414 2.01788 0.332039C0.995188 0.332039 0.166016 1.07829 0.166016 1.9987V12.832C0.166016 13.2926 0.580162 13.6654 1.09195 13.6654C1.60373 13.6654 2.01788 13.2926 2.01788 12.832V12.832ZM2.01788 1.9987C3.24222 2.00033 4.45378 2.21273 5.58967 2.6237L6.77963 3.04037C7.91533 3.45135 9.1271 3.66375 10.3514 3.66537H10.8144V9.29037H10.3514C9.12708 9.28874 7.91552 9.07635 6.77963 8.66537L5.58967 8.2487C4.45396 7.83772 3.2422 7.62533 2.01788 7.6237V1.9987Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default Flag
