import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

const BOLD_TEXT =
  'The User and Client Tool Access reports are no longer available due to changes with our User Permission data.'

const DESCRIPTION_TEXT =
  'We will be moving reporting to Power Bi, and integrate reports into Polaris. If you have any questions regarding client permissions & access, please reach out to the Pulse team.'

const Wrapper = styled.div({
  width: '100%',
  borderRadius: 8,
  background: Colors.YELLOW_100,
  padding: 24,
})

const Bold = styled.div({
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 24,
})

const Description = styled.div({
  fontSize: 16,
  fontWeight: 500,
})

export const Placeholder = () => {
  return (
    <div style={{ padding: 24 }}>
      <Wrapper>
        <CustomSvgIcon
          iconName="TriangleCaution"
          iconColor="#B88911"
          styles={{ width: 36, height: 36, marginBottom: 3 }}
        />
        <Bold>{BOLD_TEXT}</Bold>
        <Description>{DESCRIPTION_TEXT}</Description>
      </Wrapper>
    </div>
  )
}
