import React, { useState } from 'react'

import Button from 'frontend/components/Button'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

import { DropdownMenu as Menu } from './Menu'
import { useQueryString } from 'frontend/hooks'

const BUTTON_STYLE = {
  width: '34px',
  height: '24px',
  backgroundColor: '#0668D926',
}

export const InfoButton = () => {
  const { product_id } = useQueryString()

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (product_id) return null

  return (
    <>
      <Button buttonStyle={BUTTON_STYLE} onClick={handleClick} type="secondary">
        <CustomSvgIcon iconName="Info" iconColor="#0668D9" />
      </Button>
      <Menu anchorEl={anchorEl} handleClose={handleClose} />
    </>
  )
}
