import React, { Dispatch, SetStateAction } from 'react'
import { Tabs } from '@mui/base/Tabs'
import styled from '@emotion/styled'

import { useRewriteSearchString } from 'frontend/hooks'

import Policies from './Policies'
import Criteria from './Criteria'
import StyledTabsList from './TabList'

interface Props {
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
}

const Wrapper = styled.div({
  marginRight: 12,
})

export const ViewTabs = ({ activeTab, setActiveTab }: Props) => {
  const rewriteSearchString = useRewriteSearchString()
  const handleChange = (event: any, newValue: any) => {
    rewriteSearchString({ view: newValue })
    setActiveTab(newValue)
  }

  return (
    <Wrapper>
      <Tabs aria-label="view tabs" onChange={handleChange} value={activeTab}>
        <StyledTabsList>
          <Criteria activeTab={activeTab} />
          <Policies activeTab={activeTab} />
        </StyledTabsList>
      </Tabs>
    </Wrapper>
  )
}
