import React from 'react'
import styled from '@emotion/styled'

const HEADER_TEXT = 'Payer Policy Tracker'

const Wrapper = styled.div({
  padding: '10px 12px',
})

const Title = styled.h1({
  fontSize: 20,
  fontWeight: 700,
})

export const Header = () => {
  return (
    <Wrapper>
      <Title>{HEADER_TEXT}</Title>
    </Wrapper>
  )
}
