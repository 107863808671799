import React from 'react'
import styled from '@emotion/styled'

interface Props {
  checked?: boolean
  disabled?: boolean
  label?: string
  onChange: any
  name?: string
  styles?: any
}

const DEFAULT_STYLE = {
  display: 'flex',
  paddingTop: 4,
}

const Label = styled.label({
  paddingLeft: 4,
  fontSize: 12,
  letterSpacing: -0.2,
})

export const LabelCheckbox = ({
  checked,
  disabled,
  label,
  name,
  onChange,
  styles,
}: Props) => {
  const combinedStyles = { ...DEFAULT_STYLE, ...styles }

  return (
    <div style={combinedStyles}>
      <input
        checked={checked}
        disabled={disabled}
        type="checkbox"
        name={name}
        onChange={onChange}
      />
      <Label>{label}</Label>
    </div>
  )
}
