import React from 'react'
import styled from '@emotion/styled'
import { transparentize, mix } from 'polished'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  ApolloLink,
  HttpLink,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import UserProfile from './UserProfile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Colors } from 'frontend/utils/pulseStyles'

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  NavLink,
  Switch,
} from 'react-router-dom'

import { useFilterConfigFeatureFlags } from 'frontend/hooks'

import superUsersById from './utils/super-users'

import Home from './Home'

import ClientManagement from './ClientManagement'
import Orion from './Orion'
import Delphi from './Delphi'
import FlatFileExport from './FlatFileExport'
import Reports from './Reports'
import NewReports from './NewReports'
import PayerPolicyTrackerV0 from './PayerPolicyLinkMgmt/v0'
import PayerPolicyTrackerV1 from './PayerPolicyLinkMgmt/v1'

import Icon from 'frontend/components/Icon'

import { useAuth0 } from '../react-auth0-spa'
import PayerProjectsList from './PayerProjects/PayerProjectsList'
import PayerProject from './PayerProjects/PayerProject'
import PayerLivesImport from './PayerProjects/PayerLivesImport'
import { Spacing } from './utils/pulseStyles'
import Color from './utils/color'
import setupHeap from './setupHeap'
import { datadogRum } from '@datadog/browser-rum'

import { Toast } from 'frontend/components'
import NcrDataValidator from './NcrDataValidator'

const SIDE_BAR_ITEMS = [
  {
    to: '/',
    exact: true,
    iconId: 'home-1-white',
    activeColor: Color.PRIMARY,
  },
  {
    to: '/client-management',
    exact: false,
    iconId: 'phoenix-1-white',
    activeColor: Color.PHOENIX,
  },
  {
    to: '/orion',
    exact: false,
    iconId: 'orion-1-white',
    activeColor: Color.ORION,
  },
  {
    to: '/payer-projects',
    exact: false,
    iconId: 'payer-1-white',
    activeColor: Color.PAYER_PROJECTS,
  },
  {
    to: '/payer-policy',
    exact: true,
    iconId: 'payertracking-1-white',
    activeColor: Color.PAYER_PROJECTS,
  },
  {
    to: '/delphi',
    exact: false,
    iconId: 'mercury-1-white',
    activeColor: Color.DELPHI,
  },
  {
    to: '/flat-file-export/list',
    exact: true,
    iconId: 'flat-file-export',
    activeColor: Color.FLAT_FILE_EXPORT,
  },
  {
    to: '/reports',
    exact: true,
    iconId: 'report',
    activeColor: Color.REPORTS,
  },
  {
    to: '/payer-policy-v1',
    exact: true,
    iconId: 'payertracking-1-white',
    activeColor: Color.PAYER_PROJECTS,
  },
  {
    to: '/ncr-data-validator',
    exact: true,
    iconId: 'taurus-1-white',
    activeColor: Color.PAYER_PROJECTS,
    featureFlag: 'shouldShowNcrValidatorTool',
  },
]

const ICON_SIZE = 20

const getActiveLinkStyle = {
  background: transparentize(0.9, Colors.WHITE),
  opacity: 1,
}

const StyledNavLink = styled(NavLink)({
  color: Color.WHITE,
  borderRadius: 4,
  textDecoration: 'none',
  padding: `${Spacing.NORMAL} ${Spacing.NORMAL} ${Spacing.SMALL}`,
  margin: 8,
  opacity: 0.4,
  ':hover': {
    background: transparentize(0.9, Colors.WHITE),
  },
})

const PolarisSidebar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: mix(0.6, Colors.BLACK, Colors.NAVY),
  // padding: Spacing.TINY,
})

const sidebarBottomSectionStyle = {
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const logoutButtonStyle = {
  cursor: 'pointer',
  padding: Spacing.NORMAL,
}

const LogoutContainer = styled.div({
  borderRadius: 4,
  margin: `${Spacing.NORMAL} ${Spacing.TINY} 0`,
  opacity: 0.6,
  ':hover': {
    background: transparentize(0.92, Color.WHITE),
    opacity: 1,
  },
})

const SupportDocumentationContainer = styled.a({
  borderRadius: 4,
  margin: `0 ${Spacing.TINY} ${Spacing.TINY}`,
  opacity: 0.6,
  ':hover': {
    background: transparentize(0.92, Color.WHITE),
    opacity: 1,
  },
})

setupHeap()

const App = () => {
  const filterConfigFeatureFlags = useFilterConfigFeatureFlags()

  const filteredSidebarItems = filterConfigFeatureFlags(SIDE_BAR_ITEMS)
  const sidebarNavlinks = filteredSidebarItems.map(
    ({ to, iconId, activeColor, exact }) => (
      <StyledNavLink
        key={to}
        exact={exact}
        to={to}
        activeStyle={getActiveLinkStyle}
      >
        <Icon width={ICON_SIZE} height={ICON_SIZE} iconName={iconId} />
      </StyledNavLink>
    )
  )

  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    accessToken,
    user,
  } = useAuth0()
  const launchDarklyClient = useLDClient()

  localStorage.setItem(
    'REACT_APP_COMMIT_HASH',
    process.env.REACT_APP_COMMIT_HASH
  )

  try {
    const datadogRumProps = {
      clientToken: 'pube8ee68baa683946ebdb6101b9544e106',
      applicationId: 'a3e96e71-6bdc-45b7-84b8-d2f79a52fff1',
      site: 'datadoghq.com',
      service: 'polaris',
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_COMMIT_HASH,
      sampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    }
    localStorage.setItem('datadogRum', JSON.stringify(datadogRumProps))
    datadogRum.init(datadogRumProps)
  } catch (error) {
    console.error(`Datadog RUM failed to initialize: ${error}`)
  }

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    })

  if (loading) return null

  if (!isAuthenticated) {
    const {
      location: { pathname, search },
    } = window
    loginWithRedirect({ appState: { targetUrl: pathname + search } })
    return
  }

  launchDarklyClient.identify({ key: user.email })

  const httpLink = new HttpLink({
    uri: '/api/graphql',
  })

  const authLink = new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    }))

    // Call the next link in the middleware chain.
    return forward(operation)
  })

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (
        networkError &&
        networkError.result &&
        networkError.result.message === 'jwt expired'
      ) {
        localStorage.clear()
        client.clearStore().then(logoutWithRedirect)
      }

      forward(operation)
    }
  )

  const link = ApolloLink.from([authLink, errorLink, httpLink])

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })

  const isSuperUser = user.sub in superUsersById

  window.heap.identify(user.nickname)
  window.heap.addUserProperties({
    userName: user.name,
    userEmail: user.email,
    userNickName: user.nickname,
  })

  datadogRum.setUser({
    name: user.name,
    email: user.email,
    nickname: user.nickname,
  })

  return (
    <ApolloProvider client={client}>
      <Toast />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <div
            style={{ display: 'flex', height: '100vh', overflowY: 'hidden' }}
          >
            <PolarisSidebar>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '12px 0',
                  }}
                >
                  <Icon width={24} height={24} iconName="polaris-2-color" />
                </div>
              </div>
              {sidebarNavlinks}
              <div style={sidebarBottomSectionStyle}>
                <UserProfile />
                <LogoutContainer title="Log Out">
                  <FontAwesomeIcon
                    style={logoutButtonStyle}
                    onClick={() => {
                      localStorage.clear()
                      client.clearStore().then(logoutWithRedirect)
                    }}
                    icon={faSignOutAlt}
                    color={Color.WHITE}
                  />
                </LogoutContainer>
                <SupportDocumentationContainer
                  href="https://dedhamgroup.atlassian.net/servicedesk/customer/portal/2/topic/6163d9cf-df29-498b-9c5a-40300462eb76"
                  target="_blank"
                  title="Polaris Support Documentation for TDG"
                >
                  <FontAwesomeIcon
                    style={logoutButtonStyle}
                    icon={faQuestionCircle}
                    color={Color.WHITE}
                  />
                </SupportDocumentationContainer>
              </div>
            </PolarisSidebar>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/client-management" component={ClientManagement} />
              <Route path="/orion" component={Orion} />
              <Route path="/delphi" component={Delphi} />
              <Route
                exact
                path="/payer-projects"
                component={PayerProjectsList}
              />
              {isSuperUser ? (
                <Route
                  path="/payer-projects/import-lives"
                  component={PayerLivesImport}
                />
              ) : null}
              <Route
                path="/payer-projects/:projectId"
                component={PayerProject}
              />
              <Route path="/flat-file-export" component={FlatFileExport} />
              <Route path="/reports" component={NewReports} />
              <Route path="/payer-policy" component={PayerPolicyTrackerV0} />
              <Route path="/payer-policy-v1" component={PayerPolicyTrackerV1} />
              <Route path="/ncr-data-validator" component={NcrDataValidator} />
              <Redirect to="/" />
            </Switch>
          </div>
        </Router>
      </LocalizationProvider>
    </ApolloProvider>
  )
}

export default App
