import React from 'react'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface Props {
  activeTab: string
}

const Icon = ({ activeTab }: Props) => {
  const iconColor = activeTab === 'policies' ? '#0668D9' : '#9DAEC4'

  const STYLES = {
    width: '18px',
    height: '18px',
    marginRight: '5px',
  }

  return (
    <CustomSvgIcon
      iconName="SingleDocument"
      iconColor={iconColor}
      styles={STYLES}
    />
  )
}

export default Icon
