import React from 'react'
import styled from '@emotion/styled'

import { Placeholder } from './Placeholder'

const Wrapper = styled.span({
  width: '100%',
})

const Header = styled.div({
  width: '100%',
  fontSize: 24,
  fontWeight: 700,
  padding: 24,
  borderBottom: `1px solid #E6E8EA`,
})

const Reports = () => {
  return (
    <Wrapper>
      <Header>All USERS</Header>
      <Placeholder />
    </Wrapper>
  )
}
export default Reports
