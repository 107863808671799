import React from 'react'

import StyledTab from '../Tab'
import Icon from './Icon'

interface Props {
  activeTab: string
}

const Criteria = (props: Props) => (
  <StyledTab value="criteria">
    <Icon {...props} />
    Criteria
  </StyledTab>
)

export default Criteria
