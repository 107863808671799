import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Dialog } from 'frontend/components'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { useQueryString, useRewriteSearchString } from 'frontend/hooks'
import Color from 'frontend/utils/color'

import Comparison from './Comparison'
import ModalHeader from './ModalHeader'
import MostRecent from './MostRecent'
import PolicyDetails from './PolicyDetails'

const Container = styled.section({
  display: 'flex',
  height: 'calc(100% - 54px)',
  position: 'relative',
  borderTop: `1px solid ${transparentize(0.9, Color.BLACK)}`,
})

const PDFViewer = () => {
  const rewriteSearchString = useRewriteSearchString()

  const { payer_policy_document_id, product_id } = useQueryString()

  const {
    data: [mostRecentPolicy],
    update,
  }: any = usePayerPolicyDocument({
    queryInput: { id: payer_policy_document_id },
  })

  const { data: historicalPolicies }: any = usePayerPolicyDocument({
    queryInput: {
      payer_organization_id: mostRecentPolicy.payer_organization.id,
      book_of_business_id: mostRecentPolicy.book_of_business.id,
      benefit_type: mostRecentPolicy.benefit_type,
      document_type: mostRecentPolicy.document_type,
    },
  })

  const [activeTab, setActiveTab] = useState<string>('mostRecent')
  const [policyState, setPolicyState] = useState<any>({ products: {} })
  const [
    previousApprovedPolicyOptions,
    setPreviousApprovedPolicyOptions,
  ] = useState<any[]>()
  const [
    selectedPreviousApprovedPolicy,
    setSelectedPreviousApprovedPolicy,
  ] = useState<any>()

  useEffect(() => {
    if (historicalPolicies.length > 0) {
      const mostRecentProductIds = new Set<string>()
      if (product_id && typeof product_id === 'string') {
        mostRecentProductIds.add(product_id)
      } else {
        for (const product of mostRecentPolicy.products) {
          mostRecentProductIds.add(product.product.id)
        }
      }

      const previousApprovedPolicyOptions = historicalPolicies.reduce(
        (acc: any[], { id, link, approved_date, products }: any): any[] => {
          if (id === mostRecentPolicy.id || !approved_date) return acc

          for (const product of products) {
            if (mostRecentProductIds.has(product.product.id)) {
              acc.push({
                label: new Date(approved_date).toLocaleDateString(),
                value: link,
              })
              break
            }
          }

          return acc
        }, []
      )

      setPreviousApprovedPolicyOptions(previousApprovedPolicyOptions)
      if (
        !selectedPreviousApprovedPolicy &&
        previousApprovedPolicyOptions.length > 0
      )
        setSelectedPreviousApprovedPolicy(previousApprovedPolicyOptions[0])
    }
  }, [historicalPolicies])

  const closeModal = () => {
    rewriteSearchString({
      payer_policy_document_id: undefined,
      product_id: undefined,
    })
  }

  const save = async () => {
    try {
      const { products, ...rest } = policyState
      await update({
        variables: {
          input: {
            id: mostRecentPolicy.id,
            ...rest,
            products_new: Object.entries(products).map(([key, value]: any) => ({
              id: key,
              ...value,
            })),
          },
        },
      })
      closeModal()
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  return (
    <div>
      <Dialog
        contentStyle={{ borderRadius: 0 }}
        dialogStyle={{ background: 'none', padding: 'none' }}
      >
        <ModalHeader
          activeTab={activeTab}
          onCancel={closeModal}
          onSave={save}
          previousApprovedPolicyOptions={previousApprovedPolicyOptions}
          selectedPreviousApprovedPolicy={selectedPreviousApprovedPolicy}
          setActiveTab={setActiveTab}
        />
        <Container>
          <PolicyDetails
            activeTab={activeTab}
            mostRecentPolicy={mostRecentPolicy}
            policyState={policyState}
            previousApprovedPolicyOptions={previousApprovedPolicyOptions}
            selectedPreviousApprovedPolicy={selectedPreviousApprovedPolicy}
            setPolicyState={setPolicyState}
            setSelectedPreviousApprovedPolicy={
              setSelectedPreviousApprovedPolicy
            }
          />
          <MostRecent
            activeTab={activeTab}
            url={mostRecentPolicy.link}
          />
          <Comparison
            activeTab={activeTab}
            comparisonUrl={mostRecentPolicy.link}
            originalUrl={selectedPreviousApprovedPolicy?.value}
          />
        </Container>
      </Dialog>
    </div>
  )
}

export default PDFViewer
