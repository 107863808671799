import { transparentize } from 'polished'
import styled from '@emotion/styled'
import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  FILTER_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { StatusTag } from 'frontend/components'
import { Colors } from 'frontend/utils/pulseStyles'
import { toast } from 'frontend/toast'

import { PolicyStatus } from './PolicyStatus'

const { BUTTON } = DISPLAY_CELL_TYPES
const { MULTI_SELECT, STRING } = FILTER_TYPES
const POLICY_STATUS_LABEL_MAP = {
  not_applicable: 'N/A',
  approved: 'Approved',
  needs_approval: 'Needs Approval',
  incorrect_policy: 'Incorrect Policy',
}
const INSTRUCTION_STATUS_MAP = {
  good: {
    value: 'GOOD',
    color: Colors.GREEN_700,
    background: Colors.GREEN_100,
  },
  broken: {
    value: 'BROKEN',
    color: Colors.RED_800,
    background: Colors.RED_100,
  },
  not_tracked: {
    value: 'NOT TRACKED',
    color: Colors.COOL_GRAY_600,
    background: '#E2EAF4',
  },
  manually_tracked: {
    value: 'MANUALLY TRACKED',
    color: Colors.GRAY_600,
    background: '#E6E8EA',
  },
  update_instructions: {
    value: 'UPDATE INSTRUCTIONS',
    color: Colors.PURPLE_800,
    background: Colors.PURPLE_100,
  },
  update_automation: {
    value: 'UPDATE AUTOMATION',
    color: Colors.BLUE_700,
    background: Colors.BLUE_100,
  },
  no_automation: {
    value: 'NO AUTOMATION',
    color: Colors.GRAY_600,
    background: '#E6E8EA',
  },
  // TODO: Delete once data is re-seeded
  'No Automation': {
    value: 'NO AUTOMATION',
    color: Colors.GRAY_600,
    background: '#E6E8EA',
  },
}

const FilterLessHeader = styled.div({
  marginBottom: '32px', // manual measurement of difference to other headers
})

export const getLinkAutomationDetailsColumns = () => [
  {
    Header: 'Policy Status',
    accessor: 'product_policy_status',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    labelMap: POLICY_STATUS_LABEL_MAP,
    cellValueFormatter: (value, row) => {
      return <PolicyStatus payerPolicyDocument={row} />
    },
    minWidth: 160,
  },
  {
    Header: 'Instruction Status',
    accessor: 'instruction_status',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    minWidth: 214,
    cellValueFormatter: (value) => {
      if (!value) return null
      return (
        <StatusTag
          color={INSTRUCTION_STATUS_MAP[value].color}
          background={INSTRUCTION_STATUS_MAP[value].background}
          value={INSTRUCTION_STATUS_MAP[value].value}
        />
      )
    },
  },
  {
    Header: <FilterLessHeader>Payer Website Link</FilterLessHeader>,
    accessor: 'z',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Colors.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: (
        <CustomSvgIcon
          iconName="Link"
          iconColor={Colors.PRIMARY}
          styles={{ width: 14, height: 14 }}
        />
      ),
      onClick: ({ payer_website_link }) => {
        if (payer_website_link) {
          window.open(payer_website_link)
        } else {
          toast.error('No payer website link available')
        }
      },
    },
    width: 160,
    disableSortBy: true,
  },
  {
    Header: <FilterLessHeader>Document Link</FilterLessHeader>,
    accessor: 'y',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Colors.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: (
        <CustomSvgIcon
          iconName="Link"
          iconColor={Colors.PRIMARY}
          styles={{ width: 14, height: 14 }}
        />
      ),
      onClick: ({ link }) => {
        window.open(link)
      },
    },
    width: 160,
    disableSortBy: true,
  },
  {
    Header: 'Last Policy Load Date',
    accessor: 'updated_at',
    minWidth: 155,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
]
