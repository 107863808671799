import React, { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { DataTableContainer } from 'frontend/components'

import { getFlattenedData } from './getFlattenedData'
import { Header } from './Header'
import UtilityBar from './UtilityBar'
import Criteria from './Criteria'
import Policies from './Policies'

const Table = () => {
  const { view }: any = useQueryString()
  const { shouldShowPayerPolicyM2, shouldFetchLatestPolicyProducts } = useFlags()
  const {
    data: latestPayerPolicyDocuments,
    isLoading,
  }: { data: any; isLoading: boolean } = usePayerPolicyDocument({
    queryInput: {
      areLatest: true,
      version: shouldFetchLatestPolicyProducts ? 'v2' : 'v1',
    },
  })
  
  const rewriteSearchString = useRewriteSearchString()
  const [activeTab, setActiveTab] = useState(
    view ? view.toLowerCase() : 'policies'
  )

  const flattenedData = getFlattenedData(latestPayerPolicyDocuments)
  const rowCount = isLoading
    ? 0
    : view === 'criteria'
    ? flattenedData.length
    : latestPayerPolicyDocuments.length

  useEffect(() => {
    rewriteSearchString({ view: activeTab })
  }, [])

  return (
    <DataTableContainer style={{ width: 'calc(100vw - 104px)', minWidth: 600 }}>
      <Header />
      {shouldShowPayerPolicyM2 && (
        <>
          <UtilityBar
            rowCount={rowCount}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <Criteria data={flattenedData} />
        </>
      )}
      <Policies data={latestPayerPolicyDocuments} />
    </DataTableContainer>
  )
}

export default Table
