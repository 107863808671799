import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { Colors } from 'frontend/utils/pulseStyles'

const Label = styled.label({
  fontFamily: 'Inter',
  marginRight: 7,
})

export const BulkStatusButton = ({ handleClick }) => {
  return (
    <Button onClick={handleClick} style={{ padding: '5px 11px' }}>
      <Label>Set Status in Bulk</Label>
      <CustomSvgIcon
        iconName="DownCaret"
        iconColor={Colors.WHITE}
        styles={{ width: 12, height: 12 }}
      />
    </Button>
  )
}
