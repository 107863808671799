import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { transparentize } from 'polished'

import { getMenuRows } from './getMenuRows'

interface Props {
  anchorEl: any
  handleClose: () => void
}

const menuStyle = {
  paper: {
    style: {
      borderRadius: 4,
      marginTop: 4,
      width: 250,
      height: 168,
      padding: 8,
      boxShadow: `0px 2px 10px 0 ${transparentize(0.85, '#0E2539')}`,
    },
  },
}

const rowStyle = { paddingTop: 9, opacity: 1 }

export const DropdownMenu = ({ anchorEl, handleClose }: Props) => {
  const open = Boolean(anchorEl)
  const rows = getMenuRows()

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={menuStyle}
    >
      {rows.map(({ label, value }) => (
        <MenuItem disabled key={value} style={rowStyle}>
          {label}
        </MenuItem>
      ))}
    </Menu>
  )
}
