import React from 'react'
import { Select, MenuItem, ListItemIcon } from '@mui/material'

import { useQueryString } from 'frontend/hooks'

import { getStatusOptions } from './getStatusOptions'
import { getCustomIcon } from './getCustomIcon'
import { getRenderValue } from './getRenderValue'

interface Props {
  documentProduct: any
  policyState: any
  setPolicyState: any
}

const SELECT_STYLES = {
  width: '34px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.MuiOutlinedInput-notchedOutline': { border: 'none' },
  '& .MuiSelect-select': {
    padding: '0',
    textOverflow: 'clip',
  },
  '& .MuiSelect-icon': {
    right: '4px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
}

const MENU_ITEM_STYLES = {
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '18px',
  textAlign: 'left',
  letterSpacing: '.002em',
}

const StatusSelector = ({
  documentProduct,
  policyState,
  setPolicyState,
}: Props) => {
  const { product_id } = useQueryString()
  if (product_id) return null

  const statusOptions = getStatusOptions()

  const selectStatus = (e: any) => {
    const value = e.target.value

    const policy_status =
      value === documentProduct.policy_status ? undefined : value

    setPolicyState(({ products, ...policyState }: any) => {
      if (products[documentProduct.id]) {
        products[documentProduct.id].policy_status = policy_status
      } else {
        products[documentProduct.id] = { policy_status }
      }

      return {
        ...policyState,
        products,
      }
    })
  }

  return (
    <Select
      value={
        policyState.products[documentProduct.id]?.policy_status ??
        documentProduct.policy_status
      }
      sx={SELECT_STYLES}
      onChange={selectStatus}
      IconComponent={(props) => getCustomIcon(props)}
      renderValue={(selected) => getRenderValue({ selected, statusOptions })}
      MenuProps={{
        PaperProps: {
          sx: {
            transform: 'translateX(50px) !important',
          },
        },
      }}
    >
      {Object.entries(statusOptions).map(([value, { icon, label }]) => (
        <MenuItem sx={MENU_ITEM_STYLES} key={value} value={value}>
          <ListItemIcon
            sx={{
              minWidth: '0px !important',
              marginRight: '8px',
            }}
          >
            {icon}
          </ListItemIcon>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default StatusSelector
