import { transparentize } from 'polished'
import styled from '@emotion/styled'
import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  FILTER_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { Colors } from 'frontend/utils/pulseStyles'

import { PolicyStatus } from './PolicyStatus'

const { BUTTON } = DISPLAY_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const POLICY_STATUS_LABEL_MAP = {
  not_applicable: 'N/A',
  approved: 'Approved',
  needs_approval: 'Needs Approval',
  incorrect_policy: 'Incorrect Policy',
}

const FilterLessHeader = styled.div({
  marginBottom: '32px', // manual measurement of difference to other headers
})

export const getLinkAutomationDetailsColumns = () => [
  {
    Header: 'Policy Status',
    accessor: 'product_policy_status',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    labelMap: POLICY_STATUS_LABEL_MAP,
    cellValueFormatter: (value, row) => {
      return <PolicyStatus payerPolicyDocument={row} />
    },
    minWidth: 160,
  },
  {
    Header: <FilterLessHeader>AWS Document Link</FilterLessHeader>,
    accessor: 'y',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Colors.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: (
        <CustomSvgIcon
          iconName="Link"
          iconColor={Colors.PRIMARY}
          styles={{ width: 14, height: 14 }}
        />
      ),
      onClick: ({ link }) => {
        window.open(link)
      },
    },
    width: 160,
    disableSortBy: true,
  },
  {
    Header: 'Last Change Date',
    accessor: 'product_updated_at',
    minWidth: 155,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
]
