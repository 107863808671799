import React from 'react'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface Props {
  activeTab: string
}

const Icon = ({ activeTab }: Props) => {
  const iconColor = activeTab === 'criteria' ? '#0668D9' : '#9DAEC4'

  const STYLES = {
    width: '15px',
    height: '14px',
    marginRight: '5px',
  }

  return <CustomSvgIcon iconName="Flag" iconColor={iconColor} styles={STYLES} />
}

export default Icon
