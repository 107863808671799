import React from 'react'
import { Menu, MenuItem, ListItemIcon } from '@mui/material'

import { getStatusOptions } from '../StatusSelector/getStatusOptions'

interface Props {
  anchorEl: any
  handleClose: any
  setPolicyState: any
  productsState: any
  setProductsState: any
}

const MENU_ITEM_STYLES = {
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '18px',
  textAlign: 'left',
  letterSpacing: '.002em',
}

export const DropdownMenu = ({
  anchorEl,
  handleClose,
  setPolicyState,
  productsState,
  setProductsState,
}: Props) => {
  const open = Boolean(anchorEl)
  const statusOptions = getStatusOptions()

  return (
    <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
      {Object.entries(statusOptions).map(([value, { icon, label }]) => {
        const onItemClick = () =>
          useOnItemClick({
            value,
            handleClose,
            setPolicyState,
            productsState,
            setProductsState,
          })

        return (
          <MenuItem
            sx={MENU_ITEM_STYLES}
            key={value}
            value={value}
            onClick={onItemClick}
          >
            <ListItemIcon
              sx={{
                minWidth: '0px !important',
                marginRight: '8px',
              }}
            >
              {icon}
            </ListItemIcon>
            {label}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

const useOnItemClick = ({
  value,
  handleClose,
  setPolicyState,
  productsState,
  setProductsState,
}: {
  value: string
  handleClose: any
  setPolicyState: any
  productsState: any
  setProductsState: any
}) => {
  setPolicyState(({ products, ...policyState }: any) => {
    productsState.forEach((documentProductId: string) => {
      if (products[documentProductId]) {
        products[documentProductId].policy_status = value
      } else {
        products[documentProductId] = { policy_status: value }
      }
    })

    setProductsState(new Set())

    return {
      ...policyState,
      products,
    }
  })

  handleClose()
}
