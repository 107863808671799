import React from 'react'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  rowCount: number
}

const Label = styled.div({
  display: 'flex',
  padding: 10,
  fontWeight: 500,
  fontSize: 12,
  color: Colors.GRAY_400,
})

export const RowCount = ({ rowCount }: Props) => {
  const { shouldShowPayerPolicyM2 } = useFlags()

  if (!shouldShowPayerPolicyM2) return null

  return <Label>Showing {rowCount} row(s)</Label>
}
