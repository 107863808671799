import React from 'react'

import { useQueryString } from 'frontend/hooks'

import Criteria from './Criteria'

interface Props {
  data: any[]
}

const Container = ({ data }: Props) => {
  const { view } = useQueryString()
  if (view !== 'criteria') return null

  return <Criteria data={data} />
}

export default Container
