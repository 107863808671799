import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'

import { LabelCheckbox } from 'frontend/components'
import { useQueryString } from 'frontend/hooks'

import StatusSelector from './StatusSelector'
import { FlaggedButton } from './FlaggedButton'

interface Props {
  documentProduct: any
  policyState: any
  setPolicyState: any
  productsState: any
  setProductsState: any
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '46px',
  borderBottom: '.5px solid #E2EAF4',
})

const CheckboxProduct = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const StatusFlag = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
})

const ProductName = styled.span({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 18,
  letterSpacing: 0.2,
  textAlign: 'left',
})

export const Product = ({
  documentProduct,
  policyState,
  setPolicyState,
  productsState,
  setProductsState,
}: Props) => {
  const { shouldShowBulkApproval } = useFlags()
  const { view } = useQueryString()
  const shouldShowCheckboxes = shouldShowBulkApproval && view === 'policies'
  const checked = productsState.has(documentProduct.id)

  const onCheckboxChange = () =>
    useOnCheckboxChange({
      documentProduct,
      setProductsState,
    })

  return (
    <Wrapper>
      <CheckboxProduct>
        {shouldShowCheckboxes && (
          <LabelCheckbox
            checked={checked}
            onChange={onCheckboxChange}
            styles={{
              paddingTop: 0,
              marginRight: 4,
              zIndex: 0,
            }}
          />
        )}
        <ProductName>{documentProduct.product.brand_name}</ProductName>
      </CheckboxProduct>
      <StatusFlag>
        <StatusSelector
          documentProduct={documentProduct}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <FlaggedButton
          documentProduct={documentProduct}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
      </StatusFlag>
    </Wrapper>
  )
}

const useOnCheckboxChange = ({
  documentProduct,
  setProductsState,
}: {
  documentProduct: any
  setProductsState: any
}) => {
  const productId = documentProduct.id
  setProductsState((prevState: any) => {
    const newState = new Set(prevState)
    if (prevState.has(productId)) {
      newState.delete(productId)
    } else {
      newState.add(productId)
    }

    return newState
  })
}
