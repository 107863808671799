import React from 'react'

import { useQueryString } from 'frontend/hooks'

import Policies from './Policies'

interface Props {
  data: any[]
}

const Container = ({ data }: Props) => {
  const { view } = useQueryString()
  if (view !== 'policies') return null

  return <Policies data={data} />
}

export default Container
